"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildSVG = void 0;
/**
 * Decode the RLE image data into a format that's easier to consume in `buildSVG`.
 * @param image The RLE image data
 */
const decodeImage = (image) => {
    var _a, _b;
    const data = image.replace(/^0x/, '');
    const paletteIndex = parseInt(data.substring(0, 2), 16);
    const bounds = {
        top: parseInt(data.substring(2, 4), 16),
        right: parseInt(data.substring(4, 6), 16),
        bottom: parseInt(data.substring(6, 8), 16),
        left: parseInt(data.substring(8, 10), 16),
    };
    const rects = data.substring(10);
    return {
        paletteIndex,
        bounds,
        rects: (_b = (_a = rects === null || rects === void 0 ? void 0 : rects.match(/.{1,4}/g)) === null || _a === void 0 ? void 0 : _a.map(rect => [parseInt(rect.substring(0, 2), 16), parseInt(rect.substring(2, 4), 16)])) !== null && _b !== void 0 ? _b : [],
    };
};
/**
 * Given RLE parts, palette colors, and a background color, build an SVG image.
 * @param parts The RLE part datas
 * @param paletteColors The hex palette colors
 * @param bgColor The hex background color
 */
const buildSVG = (parts, paletteColors, bgColor, seedSum) => {
    const svgWithoutEndTag = parts.reduce((result, part) => {
        const svgRects = [];
        const { bounds, rects } = decodeImage(part.data);
        let currentX = bounds.left;
        let currentY = bounds.top;
        rects.forEach(rect => {
            const [length, colorIndex] = rect;
            const hexColor = paletteColors[colorIndex];
            // Do not push rect if transparent
            if (colorIndex !== 0) {
                svgRects.push(`<rect width="${length * 10}" height="10" x="${currentX * 10}" y="${currentY * 10}" fill="#${hexColor}" />`);
            }
            currentX += length;
            if (currentX === bounds.right) {
                currentX = bounds.left;
                currentY++;
            }
        });
        result += svgRects.join('');
        return result;
    }, `<svg width="320" height="320" viewBox="0 0 320 320" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges"><rect width="100%" height="100%" fill="#${bgColor}" />`);
    let overlay = '';
    const seedMod = seedSum % 20;
    if (seedMod > 7) {
        overlay = '<rect x="110" y="120" fill="#fff" width="40" height="40"/><rect x="180" y="120" fill="#fff" width="40" height="40"/>';
        if (seedMod == 8) { //up
            overlay = overlay + '<rect x="110" y="120" fill="#000" width="40" height="20"/><rect x="180" y="120" fill="#000" width="40" height="20"/>';
        }
        else if (seedMod == 9) { //down
            overlay = overlay + '<rect x="110" y="140" fill="#000" width="40" height="20"/><rect x="180" y="140" fill="#000" width="40" height="20"/>';
        }
        else if (seedMod == 10 || seedMod == 11) { //cross
            overlay = overlay + '<rect x="130" y="120" fill="#000" width="20" height="40"/><rect x="180" y="120" fill="#000" width="20" height="40"/>';
        }
        else if (seedMod == 12 || seedMod == 13) { //opposite
            overlay = overlay + '<rect x="110" y="120" fill="#000" width="20" height="40"/><rect x="200" y="120" fill="#000" width="20" height="40"/>';
        }
        else { //left
            overlay = overlay + '<rect x="110" y="120" fill="#000" width="20" height="40"/><rect x="180" y="120" fill="#000" width="20" height="40"/>';
        }
    }
    return `${svgWithoutEndTag}${overlay}</svg>`;
};
exports.buildSVG = buildSVG;
