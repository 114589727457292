export const trackerConfig = [
  {
    name: 'Noun',
    uri: 'https://nouns.wtf/',
    tokenAddress: '0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03',
    auctionHouseProxyAddress: '0x830bd73e4184cef73443c15111a1df14e495c706',    
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/nounsdao/nouns-subgraph',
    description: 'Nouns',
    handle: 'nouns',
    backgroundColor: '#d5d7e1'
  },  
  {
    name: 'Lil Noun',
    uri: 'https://lilnouns.wtf/',
    tokenAddress: '0x4b10701Bfd7BFEdc47d50562b76b436fbB5BdB3B',
    auctionHouseProxyAddress: '0x55e0f7a3bb39a28bd7bcc458e04b3cf00ad3219e',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/lilnounsdao/lil-nouns-subgraph',
    description: 'Lil Nouns',
    handle: 'lilnouns',
    backgroundColor: '#dad1cf'
  },
  {
    name: 'NounBR',
    uri: 'https://nounsbr.wtf/',
    tokenAddress: '0x36b2AA1795d8cdEf4B784Fe34045fAdC45d61e8c',
    auctionHouseProxyAddress: '0x20917fd44Eb335692DD66b42Db30Ea1Cfe0b2027',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/nounsbr/nounsbr-mainnet',
    description: 'NounsBR',
    handle: 'nounsBR',
    backgroundColor: '#dad1cf'
  },
  /*
  {
    name: 'Lost Noun',
    uri: 'https://lostnouns.wtf/',
    tokenAddress: '0x2605afbb22c59296c16ef5e477110357f760b20f',
    auctionHouseProxyAddress: '0x3a91eaacd2d5d5b7e102e17ca8bd467b79139ed5',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/12bnoun/lost-nouns-rinkeby',
    description: 'Lost Nouns',
    handle: 'lostnouns',
    backgroundColor: '#eed8ab'
  },
  */
  {
    name: 'Composables',
    uri: 'http://composables.wtf/',
    tokenAddress: '0xd6f748f54CC179201fc9747D2427f1b0C1D13581',
    auctionHouseProxyAddress: '0x11Aba4cf2fdEbd6F8Ca65A106dF758B61a196687',
    descriptorAddress: '0x0d5d8E1FED2B2c4768671ebebc3b601d28023026',
    seederAddress: '0xcc8a0fb5ab3c7132c1b2a0109142fb112c4ce515',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/composablesdao/mainnet',
    description: 'Composables',
    handle: 'Composables_',
    backgroundColor: ''
  },  
  /*
  {
    name: 'NounsTown',
    uri: 'https://nounstown.wtf/',
    tokenAddress: '0xb632fD44053B09bddDaF92dE2C212bB12Ce8DbDF',
    auctionHouseProxyAddress: '0xA2f1c683C8E2dEdFC02929f4e6f5C01D078D6274',
    descriptorAddress: '0x67771f28db47a46ddE8077d36710BeaCe5C3Ca38',
    seederAddress: '0x55A072DE03b806b1d727241828b0cf4ea33371B1',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/blocksdao/nounstown',
    description: 'NounsTown',
    handle: 'nounstown',
    backgroundColor: ''
  },  
  */
  {
    name: 'Gnar',
    uri: 'https://gnars.wtf/',
    tokenAddress: '0x558bfff0d583416f7c4e380625c7865821b8e95c',
    tokenSVGFunction: 'tokenURI',
    auctionHouseProxyAddress: '0xc28e0d3c00296dd8c5c3f2e9707361920f92a209',
    //subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/cryptosnowmickey/gnars-subgraph',
    subgraphType: 'flat',
    timerType: 'x-blocks',
    bidsDisabled: true,
    description: 'Gnars',
    handle: 'Gnars',
    backgroundColor: '#eed8ab',
    pingInterval: 60000,
  },
  {
    name: 'FOODNOUN',
    uri: 'https://www.foodnouns.wtf/',
    tokenAddress: '0xf5331380e1d19757388a6e6198bf3bdc93d8b07a',
    auctionHouseProxyAddress: '0xfaa4bbe589a39745833e2bece8d401b6195a07b1',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/yanuar-ar/foodnouns',
    description: 'FOODNOUNS',
    handle: 'foodnouns',
    backgroundColor: '#eed8ab'
  },  
  {
    name: 'Social Noun',
    uri: 'https://socialnouns.wtf/',
    tokenAddress: '0x2E5A7ea22C420B431811eCb9572e39106dDBd1D8',
    auctionHouseProxyAddress: '0x158a1e19ca4a3e95df1a03d317357ab7596e884d',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/socialnouns/social-nouns',
    description: 'Social Nouns',
    handle: 'socialnouns',
    backgroundColor: '#eed8ab'
  },
  {
    name: 'SZNoun',
    uri: 'https://www.sznouns.wtf/',
    tokenAddress: '0xd6c4cb5a3e040abc8be977dc10b658ec9072a1f4',
    auctionHouseProxyAddress: '0xbdae19bca243eb440d900f10e310b8d69c736364',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/0xgoretex/sznouns',
    description: 'SZNouns',
    handle: 'sznouns',
    backgroundColor: '#eed8ab'
  },
  {
    name: 'nuNoun',
    uri: 'https://nunoun.wtf/',
    tokenAddress: '0x4c597608A1045ac3089B4683f2787AF8f991139D',
    auctionHouseProxyAddress: '0xeEA7C299cEC5b22DF9F27472C9D8bFe99d31a690',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/nunounsdao/nunouns-subgraph-mainnet',
    description: 'nuNouns',
    handle: 'nunouns',
    backgroundColor: '#eed8ab'
  },  
  /*
  {
    name: 'Lil Goblin',
    uri: 'https://lilgoblins.wtf/',
    tokenAddress: '0xeea7043D9f76fdbC2EaC81Abbab4d1bcBEf3c279',
    auctionHouseProxyAddress: '0x152f0b7d70f1bB56F9118972b6A9009bAf6D20a8',
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/keon/lilgoblins',
    description: 'Lil Goblins',
    handle: 'lilgoblins',
    backgroundColor: '#eed8ab'
  },  
  */
  {
    name: 'Wizard',
    uri: 'https://wizardsdao.com/auction/1',
    tokenAddress: '0xc23b12eba3af92dc3ec94744c0c260cad0eed0e5',
    auctionHouseProxyAddress: '0x418cbb82f7472b321c2c5ccf76b8d9b6df47daba',
    auctionHouseFixedParam: 1,
    bidsDisabled: true,
    description: 'Wizard DAO',
    handle: 'WizardsDAO',
    backgroundColor: '#eed8ab',
    pingInterval: 60000,
  },
  /*
  {
    name: 'Wizard',
    uri: 'https://wizardsdao.com/auction/2',
    tokenAddress: '0xc23b12eba3af92dc3ec94744c0c260cad0eed0e5',
    auctionHouseProxyAddress: '0x418cbb82f7472b321c2c5ccf76b8d9b6df47daba',
    auctionHouseFixedParam: 2,
    bidsDisabled: true,
    description: 'Wizard DAO',
    handle: 'WizardsDAO',
    backgroundColor: '#eed8ab',
    pingInterval: 60000,
  },
  {
    name: 'Wizard',
    uri: 'https://wizardsdao.com/auction/3',
    tokenAddress: '0xc23b12eba3af92dc3ec94744c0c260cad0eed0e5',
    auctionHouseProxyAddress: '0x418cbb82f7472b321c2c5ccf76b8d9b6df47daba',
    auctionHouseFixedParam: 3,
    bidsDisabled: true,
    description: 'Wizard DAO',
    handle: 'WizardsDAO',
    backgroundColor: '#eed8ab',
    pingInterval: 60000,
  },  
  */
];

export default trackerConfig;