export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
  donations,
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return 'http://discord.gg/nouns';
    case ExternalURL.twitter:
      return 'https://twitter.com/nounstown';
    case ExternalURL.notion:
      return 'https://nouns.notion.site/Explore-Nouns-a2a9dceeb1d54e10b9cbf3f931c2266f';
    case ExternalURL.discourse:
      return 'https://discourse.nouns.wtf/';
     case ExternalURL.donations:
      return 'https://etherscan.io/address/0xF9ff73837000Ef96Aa122276992De8f09D9F9537';
  }
};
